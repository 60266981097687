<template>
  <div class="admin-sidebar-wrap">
      <div class="overflowY-scroll">
        <!-- User Profile -->
        <div class="user-profile">
          <!-- User Name -->
          <div class="user-name mb-5">
            <div class="d-flex align-items-center"><img @click="toHome()" src="@/assets/img/core-img/dashboard-logo.png" alt="">
              <div class="ms-3">
                <h6 class="lh-1 text-dark fz-18">
                  {{ user.first_name + " " + user.last_name }}
                  <span class="badge bg-primary fz-12 mt-2" v-show="$route.path === '/terminal'" @click="openUserProfile()">Personal Account</span>
                  <span class="badge bg-primary fz-12 mt-2" v-show="$route.path !== '/terminal'" @click="toTerminal()">To Terminal</span>
                </h6>
              </div>
            </div>
          </div>
          <!-- Balance -->
          <div class="card shadow">
            <button 
              class="btn btn-sm btn-primary rounded-pill d-sm-block"
              @click="pushDepositPage()"
            >{{ $t("dashboard.add_balance") }}</button>
            <div class="card-body text-center p-4">
              <h6 class="mb-1">Balance: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.balance : 0 }}<span class="ms-2">$</span></span>
              </h5>
              <h6 class="mb-1 mt-2">Available: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.available : 0 }}<span class="ms-2">$</span></span>
              </h5>
              <h6 class="mb-1 mt-2">Profit/Loss: </h6>
              <h5 class="mb-0 text-dark d-flex align-items-center justify-content-center">
                <span class="counter btn btn-warning rounded-pill btn-sm w-100 ">{{ userBalance ? userBalance.profit : 0 }}<span class="ms-2">$</span></span>
              </h5>
            </div>
          </div>
        </div>
        <!-- Sidenav -->
        <div class="sidenav">
          <ul>
            <li @click="setActiveMenu('forex'); fetchSymbols('forex'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/terminal/funds.png">
              Forex
            </li>
            <li @click="setActiveMenu('indices'); fetchSymbols('indices'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/terminal/monitor.png">
              Indices
            </li>
            <li @click="setActiveMenu('crypto'); fetchSymbols('crypto'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/terminal/crypto.png">
              Crypto
            </li>
            <li @click="setActiveMenu('stock'); fetchSymbols('stock'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/terminal/stock-logos.png">
              Stock
            </li>
            <li @click="setActiveMenu('metals'); fetchSymbols('metals'); changeRoute();">
              <img class="side-menu-logo" src="~@/assets/img/trade-images/terminal/commodities.png">
              Commodities
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
import router from "@/router";
import { mapState } from "vuex";

export default {
  name: "DashboardSideMenu",
  data() {
    return {
      activeMenu: "forex",
      isFirstTimeLoading: true,
      checked: true,
      isExpandedBalance: false,
      balance: null,
      available: null,
      profit: null,
      showSpinner: true,
    };
  },
  computed: mapState(["user", "symbols", "userBalance"]),
  created() {
    this.showSpinner = true;
    this.fetchSymbols("forex");
    this.showSpinner = false;
  },
  mounted() {
    this.fetchBalance();
  },
  methods: {
    pushDepositPage() {
      router.push("/account-profile/withdraw");
    },
    toTerminal: function() {
      document.querySelector('body').classList.remove("dashboard-header-active");
      router.push("/terminal");
      router.go("/terminal");
    },
    toHome: function() {
      window.location = "https://saffety.pro"
    },
    fetchBalance: function() {
      this.$store.dispatch("userBalance");
    },
    openUserProfile: function() {
      document.querySelector('body').classList.remove("dashboard-header-active");
      if (this.$route.name !== "UserProfile") {
        router.push("/account-profile");
      }
    },
    toogleTheme(e){
     let currentTheme = localStorage.getItem('theme');
      if (currentTheme) {
          document.documentElement.setAttribute('data-theme', currentTheme);
          if (currentTheme === 'light') {
              if (this.checked) {
                  this.checked = true;
              }
          }
      }
      if (e.target.checked) {
              document.documentElement.setAttribute('data-theme', 'light');
              localStorage.setItem('theme', 'light');
      } else {
              document.documentElement.setAttribute('data-theme', 'dark');
              localStorage.setItem('theme', 'dark');
      }
    },
    fetchSymbols: function(type) {
      const isStock = type === "stock" ? 1 : 0;
      if (isStock) {
        this.$store.dispatch("fetchSymbols", {
          symbolsType: type,
          countryId: 1,
          offset: 1,
          query: ""
        });
      } else {
        this.$store
          .dispatch("fetchSymbols", {
            symbolsType: type,
            countryId: 0,
            offset: 1,
            query: ""
          })
          .then(() => {
            if (this.isFirstTimeLoading) {
              this.$store.dispatch("fetchKLines", {
                symbol: this.symbols[0],
                interval: "D1"
              });
              this.$store.dispatch(
                "sendMessage",
                JSON.stringify({
                  join: "klines_d1_" + this.symbols[0].symbol.toLowerCase(),
                  notificator: "notificator"
                })
              );
              this.isFirstTimeLoading = false;
            }
          });
      }
      this.showSpinner = false;
    },
    setActiveMenu: function(type) {
      this.activeMenu = type;
      this.$store.commit("SET_SYMBOLS_FILTER", type);
      this.showSpinner = true;
      document.querySelector('body').classList.remove("dashboard-header-active");
    },
    changeRoute: function() {
      if (this.activeMenu === "private") {
        router.push("terminal");
      } else {
        router.push("/terminal/" + this.activeMenu);
      }
    }
  }
};
</script>

<style scoped>
.overflowY-scroll {
  padding-top: 10px;
}

.side-menu-logo {
  max-width: 15%;
}

.sidenav li {
  padding: 5px;
  cursor: pointer !important;
}

.sidenav li:first-child:hover {
  background-color: unset !important;
}

.sidenav li:hover,
span.badge:hover 
{
  border-radius: 50rem!important;
  background-color: #ffc007 !important;
  cursor: pointer !important;
}

.admin-sidebar-wrap {
  top: 12px;
  left: 12px;
  border-radius: 1rem;
}

.admin-sidebar-wrap .overflowY-scroll {
  height: calc(100vh - 12px);
}

</style>
